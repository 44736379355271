import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

import { ArrowBackIos } from "@mui/icons-material";
import { Autocomplete, Container, TextField, Grid, Card, CardContent, CircularProgress } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useEffect, useState } from "react";
import generalFunction from "../lib/general.function";
import internal from "../services/internal";
import { useSnackbar } from "notistack";

export default function InputData(props) {
	const [data, setdata] = useState({
		details: [],
		partner_name: "",
		inventory_type: "IN",
		reference_id: "",
		inventory_notes: "",
		inventory_date: new Date(),
		loading: false,
	});
	const [productCount, setProductCount] = useState(1);
	const [product, setProduct] = useState([]);
	useEffect(() => {
		if (!internal.getCurrentUser()) {
			props.history.push("/login");
		}
		internal.getProduct().then((r) => setProduct(r));
	}, []);

	const [alert, setAlert] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		if (alert)
			enqueueSnackbar(alert.message, {
				variant: alert.type,
			});
	}, [alert, enqueueSnackbar]);
	return (
		<Container sx={{ p: 0 }} maxWidth='xs'>
			<AppBar position='static'>
				<Toolbar>
					<IconButton
						onClick={() => {
							props.history.push("/");
						}}
						size='large'
						edge='start'
						color='inherit'
						aria-label='menu'
						sx={{ mr: 2 }}>
						<ArrowBackIos />
					</IconButton>
					<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
						Tambah Catatan Stok
					</Typography>
				</Toolbar>
			</AppBar>
			<Box sx={{ p: 3 }}>
				<ValidatorForm
					onSubmit={() => {
						console.log(data);
						setdata({ ...data, loading: true });
						internal.addProduct(data).then(
							(r) => {
								setAlert({ type: "success", message: r.data.message });
								props.history.push("/");
								setdata({ ...data, loading: false });
							},
							(error) => {
								const resMessage =
									(error.response && error.response.data && error.response.data.message) ||
									error.message ||
									error.toString();
								setdata({ ...data, loading: false });
								setAlert({ message: resMessage, type: "error" });
							}
						);
					}}>
					<Grid item xs={12} md={12}>
						{Array.from({ length: productCount }, (x, i) => i).map((r, i) => {
							return (
								<Card
									elevation={1}
									style={{
										borderRadius: "10px",
										marginBottom: "20px",
									}}>
									<CardContent>
										{" "}
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Autocomplete
													options={product}
													value={generalFunction.getSelectedData(
														product,
														data.details?.[i]?.product_id,
														"product_id"
													)}
													getOptionLabel={(option) =>
														option.product_name + ` (${option.product_id})`
													}
													onChange={(e, val) => {
														let item = [{ product_id: val?.product_id }];
														if (data?.details) {
															item = [
																...data.details.slice(0, i),
																{
																	...data?.details[i],
																	product_id: val?.product_id,
																	product_name: val?.product_name,
																},
																...data.details.slice(i + 1),
															];
														}
														setdata({
															...data,
															details: item,
														});
													}}
													renderInput={(params) => (
														<TextValidator
															margin='dense'
															value={data.details?.[i]?.product_id}
															{...params}
															label={"Produk " + (i + 1)}
															validators={["required"]}
															errorMessages={["Perlu diisi!"]}
														/>
													)}
												/>
											</Grid>

											<Grid item xs={10}>
												<TextValidator
													margin='dense'
													inputProps={{ min: 0 }}
													label={"Jumlah " + (i + 1)}
													type='number'
													value={data.details?.[i]?.product_quantity || ""}
													onChange={(e) => {
														let item = [{ product_quantity: e.target.value }];
														if (data?.details) {
															item = [
																...data.details.slice(0, i),
																{
																	...data?.details[i],
																	product_quantity: e.target.value,
																},
																...data.details.slice(i + 1),
															];
														}
														setdata({
															...data,
															details: item,
														});
													}}
													fullWidth
													validators={["required"]}
													errorMessages={["Perlu diisi!"]}
												/>
											</Grid>
											<Grid item xs={1}>
												<IconButton
													onClick={() => {
														if (data.details && data.details.length > 1) {
															let temp = data.details;
															temp.splice(i, 1);
															setdata({
																...data,
																details: temp,
															});
															setProductCount(parseInt(productCount) - 1);
														}
													}}
													size='large'>
													X
												</IconButton>
											</Grid>
										</Grid>
									</CardContent>
								</Card>
							);
						})}
					</Grid>
					<Grid item xs={12} md={12}>
						<Button
							variant='contained'
							onClick={() => {
								setProductCount(productCount + 1);
							}}>
							Tambah
						</Button>{" "}
						<Button
							onClick={() => {
								if (productCount > 1) {
									if (data?.details) {
										let transactiondetailtemp = data.details;
										transactiondetailtemp.splice(productCount - 1, 1);
										setdata({
											...data,
											details: transactiondetailtemp,
										});
									}

									setProductCount(productCount - 1);
								}
							}}
							variant='contained'
							sx={{ background: "#bbb" }}>
							Hapus
						</Button>
					</Grid>

					<ToggleButtonGroup
						fullWidth
						sx={{ mt: 2, mb: 0.5 }}
						color='primary'
						value={data?.inventory_type}
						exclusive
						onChange={(event, val) => {
							if (val) {
								setdata({ ...data, inventory_type: val });
							}
						}}>
						<ToggleButton value='IN'>Barang Masuk</ToggleButton>
						<ToggleButton value='OUT'>Barang Keluar</ToggleButton>
					</ToggleButtonGroup>
					{data?.inventory_type === "OUT" && (
						<TextValidator
							value={data?.partner_name || ""}
							margin='dense'
							validators={["required"]}
							errorMessages={["Perlu diisi"]}
							label='Nama Toko'
							onChange={(e) => {
								setdata({ ...data, partner_name: e.target.value });
							}}
							fullWidth
						/>
					)}
					<TextValidator
						value={data?.reference_id || ""}
						margin='dense'
						label='Kode SO/PO/INV (Opsional)'
						onChange={(e) => {
							setdata({ ...data, reference_id: e.target.value });
						}}
						fullWidth
					/>
					<TextValidator
						value={data?.inventory_notes || ""}
						margin='dense'
						multiline
						rows={3}
						label='Catatan (Opsional)'
						onChange={(e) => {
							setdata({ ...data, inventory_notes: e.target.value });
						}}
						fullWidth
					/>
					<MobileDatePicker
						label='Tanggal Pencatatan/Transaksi'
						inputFormat='YYYY/MM/DD'
						value={data.inventory_date}
						onChange={(val) => setdata({ ...data, inventory_date: val })}
						renderInput={(params) => <TextValidator fullWidth margin={"dense"} {...params} />}
					/>

					<Button disabled={data.loading || false} type='submit' variant='contained' sx={{ mt: 2 }} fullWidth>
						Simpan {data.loading && <CircularProgress sx={{ ml: 1 }} color='primary' size={20} />}
					</Button>
				</ValidatorForm>
			</Box>
		</Container>
	);
}
