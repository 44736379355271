import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { ButtonBase, Container } from "@mui/material";

import { useEffect, useState } from "react";
import internal from "../services/internal";
import GeneralFunction from "../lib/general.function";
import Navigation from "../components/Navigation";
import DetailModal from "../components/modal/Detail";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useSnackbar } from "notistack";
import { Skeleton } from "@mui/material";

export default function Home(props) {
	const [alert, setAlert] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	useEffect(() => {
		if (alert)
			enqueueSnackbar(alert.message, {
				variant: alert.type,
			});
	}, [alert, enqueueSnackbar]);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [period, setPeriod] = useState([]);
	useEffect(async () => {
		setLoading(true);
		if (!internal.getCurrentUser()) {
			props.history.push("/login");
		}
		let perioddata = await internal.getPeriod();
		setPeriod(perioddata);
		let current = perioddata[0];
		setData({ ...data, period: current.name });
	}, []);

	const getData = () => {
		if (data?.period) {
			setLoading(true);
			internal
				.getDashboard(
					GeneralFunction.getSelectedData(period, data?.period, "name").month,
					GeneralFunction.getSelectedData(period, data?.period, "name").year
				)
				.then((r) => {
					const groups = r.reduce((groups, data2) => {
						const date = data2.inventory_date.split("T")[0];
						if (!groups[date]) {
							groups[date] = [];
						}
						groups[date].push(data2);
						return groups;
					}, {});
					setLoading(false);
					setData({ ...data, dashboard: groups, detail_modal: false });
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					setAlert({ message: resMessage, type: "error" });
				});
		}
	};
	useEffect(() => {
		getData();
	}, [data?.period]);
	return (
		<Container sx={{ p: 0 }} maxWidth='xs'>
			<DetailModal
				setAlert={(e) => setAlert(e)}
				getData={getData}
				handleClose={() => setData({ ...data, detail_modal: false })}
				open={data?.detail_modal || false}
				id={data?.detail_id}
			/>
			<AppBar sx={{ background: "#fff", color: "#000", boxShadow: "none" }} position='sticky'>
				<Toolbar sx={{ background: "#FF7F50", color: "#fff" }}>
					<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
						Dashboard
					</Typography>
				</Toolbar>
				<Box sx={{ px: 3, pt: 3 }}>
					<Navigation {...props} />
				</Box>
			</AppBar>

			<Box sx={{ p: 3 }}>
				<Button
					variant='contained'
					sx={{ mt: 2, position: "fixed", p: 2, bottom: 0, left: 0, right: 0, zIndex: 999 }}
					fullWidth
					onClick={() => {
						props.history.push("/input");
					}}>
					Tambah Pencatatan Stok
				</Button>
				{loading ? (
					<>
						<Skeleton height={50} />
						<Skeleton height={50} />
						<Skeleton height={50} />
						<Skeleton height={50} />
						<Skeleton height={50} />
						<Skeleton height={50} />
						<Skeleton height={50} />
						<Skeleton height={50} />
					</>
				) : (
					<>
						<Box sx={{ my: 0 }}>
							<Autocomplete
								options={period}
								value={GeneralFunction.getSelectedData(period, data.period, "name")}
								getOptionLabel={(option) => option.name}
								onChange={(e, val) => {
									if (val)
										setData({
											...data,
											period: val.name,
										});
								}}
								renderInput={(params) => (
									<TextField margin='dense' value={data?.period} {...params} placeholder={"Bulan"} />
								)}
							/>
						</Box>
						{data.dashboard &&
							Object.keys(data.dashboard)
								.slice(0, 7)
								.map((date, i) => {
									return (
										<>
											<Box sx={{ background: "#ddd", px: 1, mt: 1 }}>
												<Typography variant={"subtitle1"} fontWeight={"bold"}>
													{GeneralFunction.formatDate(date)}
												</Typography>
											</Box>
											{data.dashboard?.[date].map((r) => (
												<ButtonBase
													onClick={() => {
														setData({
															...data,
															detail_modal: true,
															detail_id: r.inventory_id,
														});
													}}
													container
													sx={{
														width: "100%",
														border: "1px solid #ccc",
														textAlign: "left",
														borderRadius: "10px",
														p: 2,
														mt: 1,
														position: "relative",
													}}>
													<Grid container alignItems='center'>
														<Grid item xs={10}>
															<Box
																sx={{
																	overflow: "hidden",
																	textOverflow: "ellipsis",
																	width: "95%",
																}}>
																{r.inventories_details.map((r) => (
																	<>
																		<Typography
																			sx={{
																				overflow: "hidden",
																				whiteSpace: "nowrap",
																				textOverflow: "ellipsis",
																			}}
																			variant='caption'
																			fontWeight='bold'>
																			{r.product_quantity} X {r.product_name}
																		</Typography>
																		<br />
																	</>
																))}

																<Typography noWrap variant='caption'>
																	{GeneralFunction.formatTime(r.inventory_date)} |{" "}
																	{r.inventories_details.length} Produk{" "}
																	{r.partner_name && " | " + r.partner_name}
																</Typography>
															</Box>
														</Grid>
														<Grid item xs={2}>
															<Typography
																fontWeight='bold'
																sx={{
																	color: r.inventory_type === "IN" ? "green" : "red",
																}}>
																{r.inventory_type === "IN" ? "Masuk" : "Keluar"}
															</Typography>
														</Grid>
													</Grid>
												</ButtonBase>
											))}
										</>
									);
									// date,
									// games: groups[date]
								})}
					</>
				)}
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
			</Box>
		</Container>
	);
}
