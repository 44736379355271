import logo from "./logo.svg";
import "./App.css";
import InputData from "./pages/Input";
import Home from "./pages/Home";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import Stok from "./pages/Stok";
import { LicenseInfo } from "@mui/x-data-grid-pro";
// import "html5-qrcode/dist/html5-qrcode.min.js";
LicenseInfo.setLicenseKey(
	"10f32784340c0c560049e9ed86c54f5dT1JERVI6U3VyeWFVdGFtYSxFWFBJUlk9MzMxNjc3NTQ1ODYyOTMsS0VZVkVSU0lPTj0x"
);
function App() {
	return (
		<>
			<Switch>
				<Route exact path='/input' component={InputData} />
				<Route exact path='/stok' component={Stok} />
				<Route exact path='/login' component={Login} />
				<Route exact path='/' component={Home} />
			</Switch>
		</>
	);
}

export default App;
