import { Grid, MenuItem, Pagination, Select } from "@mui/material";
import { useGridApiContext, useGridState } from "@mui/x-data-grid-pro";
export default function CustomPagination(props) {
	const { setPageSize, PageSize } = props;
	const apiRef = useGridApiContext();
	const [state] = useGridState(apiRef);
	return (
		<Grid container sx={{ justifyContent: "center", alignItems: "center", p: 2 }}>
			<Grid item xs={12} sx={{ mb: 2, px: 3, alignItems: "center" }}>
				<Grid container sx={{ alignItems: "center" }}>
					<Grid item xs={6}>
						{apiRef.current.getVisibleRowModels().size} data
					</Grid>
					<Grid item xs={6}>
						<Select
							size='small'
							sx={{ fontSize: "12px", float: "right", mr: 1 }}
							value={PageSize}
							onChange={(event) => {
								setPageSize(event.target.value);
								apiRef.current.setPage(0);
							}}>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={25}>25</MenuItem>
							<MenuItem value={50}>50</MenuItem>
							<MenuItem value={100}>100</MenuItem>
							<MenuItem value={200}>200</MenuItem>
							<MenuItem value={500}>500</MenuItem>
						</Select>
					</Grid>
				</Grid>
			</Grid>

			<Pagination
				size='small'
				sx={{ fontSize: "10px" }}
				color='primary'
				shape='rounded'
				count={Math.ceil(apiRef.current.getVisibleRowModels().size / PageSize)}
				page={state.pagination.page + 1}
				onChange={(event, value) => apiRef.current.setPage(value - 1)}
			/>
		</Grid>
	);
}
