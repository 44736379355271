import Grid from "@mui/material/Grid";
import { ButtonBase } from "@mui/material";
import internal from "../services/internal";
import { LogoutOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";

export default function Navigation(props) {
	return (
		<>
			{" "}
			<Grid container spacing={2} sx={{ borderBottom: "1px dashed #ccc", pb: 2 }}>
				<Grid item xs={5}>
					<ButtonBase
						sx={{
							border: "1px solid #ccc",
							borderRadius: "10px",
							p: 1,
							width: "100%",
							fontSize: "16px",
						}}
						onClick={() => {
							props.history.push("/");
						}}>
						Dashboard
					</ButtonBase>
				</Grid>
				<Grid item xs={5}>
					<ButtonBase
						sx={{
							border: "1px solid #ccc",
							borderRadius: "10px",
							p: 1,
							width: "100%",
							fontSize: "16px",
						}}
						onClick={() => {
							props.history.push("/stok");
						}}>
						Daftar Stok
					</ButtonBase>
				</Grid>
				<Grid item xs={2}>
					<ButtonBase
						onClick={() => {
							internal.logout();
						}}
						sx={{
							border: "1px solid #ccc",
							borderRadius: "10px",
							p: 1,
							width: "100%",
							fontSize: "16px",
						}}>
						<LogoutOutlined />
					</ButtonBase>
				</Grid>
			</Grid>
		</>
	);
}
