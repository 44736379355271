import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import generalFunction from "../../lib/general.function";
import internal from "../../services/internal";
import { Skeleton } from "@mui/material";
import { Button } from "@mui/material";
import DeleteModal from "./Delete";
export default function DetailModal({ handleClose, open, id, setAlert, getData }) {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (id) {
			setLoading(true);
			internal
				.getInventoryDetail(id)
				.then((r) => {
					console.log(r);
					setData(r);
					setLoading(false);
				})
				.catch((error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data.message) ||
						error.message ||
						error.toString();

					setAlert({ message: resMessage, type: "error" });
				});
		}
	}, [id]);
	return (
		<>
			<DeleteModal
				handleClose={() => {
					setData({ ...data, delete_modal: false });
				}}
				open={data.delete_modal || false}
				close={() => {
					setData({ ...data, delete_modal: true });
				}}
				goDelete={() => {
					internal
						.deleteInvetory(id)
						.then((r) => {
							setAlert({ message: r.message, type: "success" });
							setData({ ...data, delete_modal: false });

							getData();
						})
						.catch((error) => {
							const resMessage =
								(error.response && error.response.data && error.response.data.message) ||
								error.message ||
								error.toString();

							setAlert({ message: resMessage, type: "error" });
						});
				}}
			/>
			<Dialog fullWidth maxWidth='sm' onClose={handleClose} open={open}>
				<DialogTitle sx={{ borderBottom: "1px solid #ccc", mb: 2 }}>
					{data.loading ? (
						<>
							<Skeleton height={50} />
						</>
					) : (
						<>
							{generalFunction.formatDate(data.inventory_date) +
								" " +
								generalFunction.formatTime(data.inventory_date)}
							<Button
								sx={{ right: "10px", border: "1px solid #f0f0f0", top: "13px", position: "absolute" }}
								onClick={handleClose}>
								X
							</Button>
						</>
					)}
				</DialogTitle>
				<DialogContent>
					{data.loading ? (
						<>
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
						</>
					) : (
						<>
							<Typography fontWeight='bold'>Detail Barang</Typography>
							{data.inventories_details &&
								data.inventories_details.map((r) => (
									<>
										<Typography sx={{ borderBottom: "1px solid #ccc", p: 1 }}>
											{r.product_quantity} X {r.product_name} ({r.product_id})
										</Typography>
									</>
								))}
							<br />
							<Typography
								fontWeight='bold'
								sx={{
									border: `1px solid ${data.inventory_type === "IN" ? "green" : "red"}`,
									color: data.inventory_type === "IN" ? "green" : "red",
									textAlign: "center",
									width: "200px",
									p: 1,
									mb: 2,
								}}>
								{data.inventory_type === "IN" ? "Barang Masuk" : "Barang Keluar"}
							</Typography>
							{data?.partner_name && (
								<>
									<Typography fontWeight='bold'>Nama Toko</Typography>
									<Typography sx={{ p: 1 }}>{data.partner_name}</Typography>
								</>
							)}
							<Typography fontWeight='bold'>Catatan</Typography>
							<Typography sx={{ p: 1 }}>{data.inventory_note ?? "Tidak ada catatan"}</Typography>
							<Button
								variant='contained'
								fullWidth
								sx={{ mt: 3 }}
								onClick={() => {
									setData({ ...data, delete_modal: true });
								}}>
								Hapus Catatan Ini
							</Button>
						</>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}
