import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DeleteModal(props) {
	const { open, handleClose, goDelete } = props;
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'>
			<DialogTitle id='alert-dialog-title'>{"Apa Anda yakin menghapus ini?"}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>
					Pastikan Anda melakukan hal yang benar sebelum menghapus data ini.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					Batal
				</Button>
				<Button onClick={goDelete} color='primary' autoFocus>
					Hapus Sekarang
				</Button>
			</DialogActions>
		</Dialog>
	);
}
