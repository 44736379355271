import { Container, Box, Typography, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import internal from "../services/internal";
import { useSnackbar } from "notistack";
export default function Login(props) {
	const [data, setdata] = useState({ username: "", password: "" });
	const [rememberMe, setRememberMe] = useState(false);
	const [alert, setAlert] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	const loginNow = () => {
		internal.signin(data.username, data.password, rememberMe).then(
			() => {
				console.log("test");
				props.history.push("/");
				window.location.reload();
			},
			(error) => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) ||
					error.message ||
					error.toString();
				setAlert({ message: resMessage, type: "error" });
			}
		);
	};
	useEffect(() => {
		if (alert)
			enqueueSnackbar(alert.message, {
				variant: alert.type,
			});
	}, [alert, enqueueSnackbar]);
	useEffect(() => {
		if (internal.getCurrentUser()) {
			props.history.push("/");
		}
		const rememberMe = localStorage.getItem("rememberMe") === "true";
		setRememberMe(rememberMe);
		const username = rememberMe ? localStorage.getItem("remember_username") : "";
		setdata({ ...data, username: username });
	}, []);
	return (
		<Container sx={{ p: 2 }} maxWidth='sm'>
			<center>
				<img src='https://cv-mitrabangunan.com/assets/logo.png' alt='Mitra Bangunan' style={{ width: "80%" }} />
			</center>
			<Box sx={{ border: "1px solid #ccc", p: 2, py: 3, mt: 3 }}>
				<Typography variant='h6' fontWeight='bold'>
					Gudang Jawa Tengah - Mitra Bangunan
				</Typography>
				<ValidatorForm
					onSubmit={() => {
						loginNow();
					}}>
					<TextValidator
						value={data?.username || ""}
						margin='dense'
						validators={["required"]}
						errorMessages={["Perlu diisi"]}
						label='Nama Pengguna'
						onChange={(e) => {
							setdata({ ...data, username: e.target.value });
						}}
						size='small'
						fullWidth
					/>
					<TextValidator
						value={data?.password || ""}
						margin='dense'
						size='small'
						validators={["required"]}
						errorMessages={["Perlu diisi"]}
						label='Kata Sandi'
						type='password'
						onChange={(e) => {
							setdata({ ...data, password: e.target.value });
						}}
						fullWidth
					/>
					<FormControlLabel
						sx={{ float: "right" }}
						control={
							<Checkbox
								checked={rememberMe}
								onChange={(e) => {
									setRememberMe(e.target.checked);
								}}
							/>
						}
						label='Ingat saya?'
					/>
					<Button type='submit' variant='contained' fullWidth sx={{ mt: 2, mb: 1 }}>
						Masuk
					</Button>
				</ValidatorForm>
			</Box>
		</Container>
	);
}
