class GeneralFunction {
  getSelectedData(data, key_value, key_column) {
    let result =
      (data &&
        data.length > 0 &&
        data.find((opt) => opt[key_column] == key_value && opt)) ||
      null;

    return result;
  }
  formatTime(date) {
    var d = new Date(date),
      hours = "" + d.getHours(),
      minutes = "" + d.getMinutes();

    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;

    return [hours, minutes].join(":");
  }
  formatDate(date) {
    var date = new Date(date);
    var tahun = date.getFullYear();
    var bulan = date.getMonth();
    var tanggal = date.getDate();

    var jam = date.getHours();
    var menit = date.getMinutes();
    var detik = date.getSeconds();

    switch (bulan) {
      case 0:
        bulan = "Januari";
        break;
      case 1:
        bulan = "Februari";
        break;
      case 2:
        bulan = "Maret";
        break;
      case 3:
        bulan = "April";
        break;
      case 4:
        bulan = "Mei";
        break;
      case 5:
        bulan = "Juni";
        break;
      case 6:
        bulan = "Juli";
        break;
      case 7:
        bulan = "Agustus";
        break;
      case 8:
        bulan = "September";
        break;
      case 9:
        bulan = "Oktober";
        break;
      case 10:
        bulan = "November";
        break;
      case 11:
        bulan = "Desember";
        break;
    }
    if (tanggal.toString().length < 2) tanggal = "0" + tanggal.toString();

    return tanggal + " " + bulan + " " + tahun;
  }
}

export default new GeneralFunction();
