export default {
  // Root
  rootGridLabel: "grid",
  noRowsLabel: "Tidak ada data",
  errorOverlayDefaultLabel: "Terjadi sebuah kesalahan.",

  // Density selector toolbar button text
  toolbarDensity: "Ukuran baris",
  toolbarDensityLabel: "Ukuran baris",
  toolbarDensityCompact: "Kecil",
  toolbarDensityStandard: "Standar",
  toolbarDensityComfortable: "Besar",

  // Columns selector toolbar button text
  toolbarColumns: "Kolom",
  toolbarColumnsLabel: "Pilih kolom",

  // Filters toolbar button text
  toolbarFilters: "Filters",
  toolbarFiltersLabel: "Show filters",
  toolbarFiltersTooltipHide: "Hide filters",
  toolbarFiltersTooltipShow: "Show filters",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} active filters` : `${count} active filter`,

  // Export selector toolbar button text
  toolbarExport: "Ekspor",
  toolbarExportLabel: "Ekspor",
  toolbarExportCSV: "Ekspor CSV",

  // Columns panel text
  columnsPanelTextFieldLabel: "Cari kolom",
  columnsPanelTextFieldPlaceholder: "Judul kolom",
  columnsPanelDragIconLabel: "Reorder kolom",
  columnsPanelShowAllButton: "Tampilkan semua",
  columnsPanelHideAllButton: "Sembunyikan semua",

  // Filter panel text
  filterPanelAddFilter: "Tambah filter",
  filterPanelDeleteIconLabel: "Hapus",
  filterPanelOperators: "Operator",
  filterPanelOperatorAnd: "And",
  filterPanelOperatorOr: "Or",
  filterPanelColumns: "Kolom",
  filterPanelInputLabel: "Value",
  filterPanelInputPlaceholder: "Filter value",

  // Filter operators text
  filterOperatorContains: "contains",
  filterOperatorEquals: "equals",
  filterOperatorStartsWith: "starts with",
  filterOperatorEndsWith: "ends with",
  filterOperatorIs: "is",
  filterOperatorNot: "is not",
  filterOperatorAfter: "is after",
  filterOperatorOnOrAfter: "is on or after",
  filterOperatorBefore: "is before",
  filterOperatorOnOrBefore: "is on or before",

  // Filter values text
  filterValueAny: "any",
  filterValueTrue: "true",
  filterValueFalse: "false",

  // Column menu text
  columnMenuLabel: "Daftar",
  columnMenuShowColumns: "Tampilkan",
  columnMenuFilter: "Filter",
  columnMenuHideColumn: "Sembunyikan",
  columnMenuUnsort: "Unsort",
  columnMenuSortAsc: "Sort by ASC",
  columnMenuSortDesc: "Sort by DESC",

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} active filters` : `${count} active filter`,
  columnHeaderFiltersLabel: "Show filters",
  columnHeaderSortIconLabel: "Sort",

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} rows selected`
      : `${count.toLocaleString()} row selected`,

  // Total rows footer text
  footerTotalRows: "Jumlah kolom:",

  // Checkbox selection text
  checkboxSelectionHeaderName: "Checkbox selection",

  // Boolean cell text
  booleanCellTrueLabel: "true",
  booleanCellFalseLabel: "false",
};
