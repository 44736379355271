import { Container, createTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Navigation from "../components/Navigation";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import internal from "../services/internal";
import CustomNoRowsOverlay from "../lib/CustomNoRowsOverlay";
import CustomPagination from "../lib/CustomPagination";
import datagridlocale from "../lib/datagridlocale";
import { Clear } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { Skeleton } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import Fuse from "fuse.js";
export default function Stok(props) {
	const defaultTheme = createTheme();
	const useStyles = makeStyles(
		(theme) =>
			createStyles({
				root: {
					padding: theme.spacing(0.5, 0.5, 0),
					justifyContent: "space-between",
					display: "flex",
					alignItems: "flex-start",
					flexWrap: "wrap",
				},
				textField: {
					[theme.breakpoints.down("xs")]: {
						width: "100%",
					},
					margin: theme.spacing(1, 0.5, 1.5),
					"& .MuiSvgIcon-root": {
						marginRight: theme.spacing(0.5),
					},
					"& .MuiInput-underline:before": {
						borderBottom: `1px solid ${theme.palette.divider}`,
					},
				},
			}),
		{ defaultTheme }
	);
	const classes = useStyles();

	const [oridata, setoridata] = useState([]);
	const [data, setdata] = useState([]);
	const [searchText, setSearchText] = useState("");
	function escapeRegExp(value) {
		return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
	}

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);
		// const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
		// const filteredRows = oridata.filter((row) => {
		// 	return Object.keys(row).some((field) => {
		// 		return searchRegex.test(row[field].toString());
		// 	});
		// });
		// setdata(filteredRows);
		let fuse = new Fuse(oridata, { keys: ["product_name"] });
		let filteredRows = fuse.search(searchValue).map((r) => r.item);
		if (searchValue) {
			setdata(filteredRows);
		} else {
			setdata(oridata);
		}
	};
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (!internal.getCurrentUser()) {
			props.history.push("/login");
		}
		setLoading(true);
		internal.getProduct().then((r) => {
			let data = r.map((r) => ({
				id: r.product_id,
				...r,
			}));
			let notzero = data
				.filter((r) => r.product_stock != 0)
				.sort((a, b) => parseInt(b.product_stock) - parseInt(a.product_stock));
			data = data.filter((r) => r.product_stock == 0);
			data = [...notzero, ...data];
			setdata(data);
			setoridata(data);
			setLoading(false);
		});
	}, []);

	const [PageSize, setPageSize] = useState(25);
	const columns = [
		{ field: "product_name", flex: 1, headerName: "Produk", type: "string" },
		{ field: "product_stock", flex: 0.5, headerName: "Stok", type: "number" },
	];
	const [sortModel, setSortModel] = useState([
		// {
		// 	field: "product_stock",
		// 	sort: "desc",
		// },
	]);
	return (
		<>
			<Container sx={{ p: 0 }} maxWidth='xs'>
				<AppBar sx={{ background: "#fff", color: "#000", boxShadow: "none" }} position='sticky'>
					<Toolbar sx={{ background: "#FF7F50", color: "#fff" }}>
						<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
							Daftar Stok
						</Typography>
					</Toolbar>
					<Box sx={{ px: 3, pt: 3 }}>
						<Navigation {...props} />
						<TextField
							sx={{ pt: 1 }}
							fullWidth
							variant='standard'
							value={searchText}
							onChange={(event) => requestSearch(event.target.value)}
							placeholder='Search…'
							className={classes.textField}
							InputProps={{
								startAdornment: <Search fontSize='small' />,
								endAdornment: (
									<IconButton
										title='Clear'
										aria-label='Clear'
										size='small'
										style={{ visibility: props.value ? "visible" : "hidden" }}
										onClick={() => requestSearch("")}>
										<Clear fontSize='small' />
									</IconButton>
								),
							}}
						/>
					</Box>
				</AppBar>
				<Box sx={{ p: 3 }}>
					{loading ? (
						<>
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
							<Skeleton height={50} />
						</>
					) : (
						<>
							<DataGridPro
								sortModel={sortModel}
								onSortModelChange={(model) => setSortModel(model)}
								style={{ minHeight: "400px" }}
								density='compact'
								rowsPerPageOptions={[10, 25, 50, 100]}
								pagination
								autoHeight
								pageSize={PageSize}
								onPageSizeChange={(r) => setPageSize(r)}
								rows={data}
								columns={columns}
								localeText={datagridlocale}
								components={{
									// Toolbar: GridToolbar,
									NoRowsOverlay: CustomNoRowsOverlay,
									Pagination: CustomPagination,
								}}
								componentsProps={{
									pagination: { data: data, setPageSize, PageSize },
								}}
							/>
						</>
					)}
				</Box>
			</Container>
		</>
	);
}
