import axios from "axios";
import AuthHeader from "./header";
import Config from "./server.config.js";
const API_URL = Config.API_DOMAIN + "";
class InternalService {
	//signin
	signin(EMPLOYEE_USERNAME, EMPLOYEE_PASSWORD, rememberMe) {
		return axios
			.post(API_URL + "employees/login", {
				EMPLOYEE_USERNAME,
				EMPLOYEE_PASSWORD,
			})
			.then((response) => {
				if (response.data.accessToken) {
					localStorage.setItem("user", JSON.stringify(response.data));
					localStorage.setItem("rememberMe", rememberMe);
					localStorage.setItem("remember_username", rememberMe ? EMPLOYEE_USERNAME : "");
				}
				return response.data;
			});
	}
	logout() {
		localStorage.removeItem("user");
		window.location.reload();
	}
	getCurrentUser() {
		return JSON.parse(localStorage.getItem("user"));
	}
	getProduct() {
		return axios.get(API_URL + "products/all", { headers: AuthHeader() }).then((r) => r.data);
	}
	addProduct(data) {
		return axios.post(API_URL + "inventories/create", { ...data }, { headers: AuthHeader() });
	}

	getDashboard(month, year) {
		return axios
			.get(API_URL + "inventories/all/null/" + `${month}/${year}`, {
				headers: AuthHeader(),
			})
			.then((r) => r.data);
	}
	getInventory() {
		return axios.get(API_URL + "inventories/all", { headers: AuthHeader() }).then((r) => r.data);
	}
	getPeriod() {
		return axios.get(API_URL + "inventories/period", { headers: AuthHeader() }).then((r) => r.data);
	}
	getInventoryDetail(id) {
		return axios.get(API_URL + "inventories/details/" + id, { headers: AuthHeader() }).then((r) => r.data);
	}
	deleteInvetory(id) {
		return axios.delete(API_URL + "inventories/delete/" + id, { headers: AuthHeader() }).then((r) => r.data);
	}
}
export default new InternalService();
